import React, {lazy, Suspense} from 'react';
const PaymentLazyHelper = lazy(() => import('./PaymentLazyHelper'));

const PaymentPage =()=>  {
    return (
        <React.Fragment>
            <Suspense fallback={<div style={{height:"100vh"}}/>}>
                <PaymentLazyHelper/>
            </Suspense>
        </React.Fragment>
    );
};

export default PaymentPage;
