export default function userReducer(state = {
    userLoggedIn: null,
    enteredEmail: null,
    register: {
        fetching: false,
        fetched: false
    },
    confirmation: {
        fetching: false,
        fetched: false
    },
    resendConfirmationEmail: {
        fetching: false,
        fetched: false
    },
    login: {
        fetching: false,
        fetched: false,
    },
    logout: {
        fetching: false,
        fetched: false
    },
    sendResetPasswordEmail: {
        fetching: false,
        fetched: false
    },
    sendNewPassword: {
        fetching: false,
        fetched: false
    },
    profile: {
        fetching: false,
        fetched: false,
        data: null
    },
    token: {
        fetching: false,
        fetched: false
    },
    apiKey: {
        fetching: false,
        fetched: false,
    },
    userPermission: null
}, action) {
    switch (action.type) {
        case "REGISTER_USER_PENDING": {
            return {
                ...state,
                register: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "REGISTER_USER_REJECTED": {
            return {
                ...state,
                register: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "REGISTER_USER_FULFILLED": {
            return {
                ...state,
                register: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "CLEAR_REGISTRATION_STATE": {
            return {
                ...state,
                register: {
                    fetched: false
                }
            }
        }
        case "SET_REGISTRATION_EMAIL": {
            return {
                ...state,
                enteredEmail: action.enteredEmail
            }
        }
        case "CONFIRM_USER_PENDING": {
            return {
                ...state,
                confirmation: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "CONFIRM_USER_REJECTED": {
            return {
                ...state,
                confirmation: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "CONFIRM_USER_FULFILLED": {
            return {
                ...state,
                confirmation: {
                    fetching: false,
                    fetched: true
                },
                userLoggedIn: true
            }
        }
        case "RESEND_CONFIRMATION_EMAIL_PENDING": {
            return {
                ...state,
                resendConfirmationEmail: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "RESEND_CONFIRMATION_EMAIL_REJECTED": {
            return {
                ...state,
                resendConfirmationEmail: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "RESEND_CONFIRMATION_EMAIL_FULFILLED": {
            return {
                ...state,
                resendConfirmationEmail: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "LOGIN_USER_PENDING": {
            return {
                ...state,
                login: {
                    fetching: true,
                    fetched: false
                },
            }
        }
        case "LOGIN_USER_REJECTED": {
            return {
                ...state,
                login: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "LOGIN_USER_FULFILLED": {
            return {
                ...state,
                login: {
                    fetching: false,
                    fetched: true
                },
                userLoggedIn: true
            }
        }
        case "LOGOUT_USER_PENDING": {
            return {
                ...state,
                logout: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "LOGOUT_USER_REJECTED": {
            return {
                ...state,
                logout: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "LOGOUT_USER_FULFILLED": {
            return {
                ...state,
                logout: {
                    fetching: false,
                    fetched: true
                },
                userLoggedIn: false,
                profile:  {
                    fetched: false,
                    fetching: false
                }
            }
        }
        case "SEND_RESET_PASSWORD_EMAIL_PENDING": {
            return {
                ...state,
                sendResetPasswordEmail: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "SEND_RESET_PASSWORD_EMAIL_REJECTED": {
            return {
                ...state,
                sendResetPasswordEmail: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "SEND_RESET_PASSWORD_EMAIL_FULFILLED": {
            return {
                ...state,
                sendResetPasswordEmail: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "SEND_NEW_PASSWORD_PENDING": {
            return {
                ...state,
                sendNewPassword: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "SEND_NEW_PASSWORD_REJECTED": {
            return {
                ...state,
                sendNewPassword: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "SEND_NEW_PASSWORD_FULFILLED": {
            return {
                ...state,
                sendNewPassword: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_USER_PROFILE_PENDING": {
            return {
                ...state,
                profile: {
                    fetching: true,
                    fetched: false
                },
                userLoggedIn: null
            }
        }
        case "GET_USER_PROFILE_REJECTED": {
            return {
                ...state,
                profile: {
                    fetching: false,
                    fetched: false
                },
                userLoggedIn: false
            }
        }
        case "GET_USER_PROFILE_FULFILLED": {
            return {
                ...state,
                profile: {
                    fetching: false,
                    fetched: true,
                    data: action.payload.data,
                },
                userLoggedIn: true
            }
        }
        case "INIT_TOKEN_PENDING": {
            return {
                ...state,
                initToken: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "INIT_TOKEN_REJECTED": {
            return {
                ...state,
                initToken: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "INIT_TOKEN_FULFILLED": {
            return {
                ...state,
                initToken: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "USER_PERMISSION": {
            return {
                ...state,
              userPermission: action.userPermission
            }
        }
        case "GET_API_KEY_PENDING": {
            return {
                ...state,
                apiKey: {
                    fetching: true,
                    fetched: false
                },
            }
        }
        case "GET_API_KEY_REJECTED": {
            return {
                ...state,
                apiKey: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_API_KEY_FULFILLED": {
            return {
                ...state,
                apiKey: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        default : {
            return state
        }
    }
}