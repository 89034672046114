import React from 'react';


function ErrorAnimation() {

    return (
        <div className='error__anim'>
            <div className="bg-auth bg-shadow error__anim--image--shadow"/>
            <div className="bg-auth bg-sad_laptop error__anim--image--laptop"/>
        </div>
    );
}

export default ErrorAnimation;
