import {combineReducers} from "redux";
import userReducer from "./userReducer";
import plansReducer from "./plansReducer";
import messageReducer from "./messageReducer";
import releaseNotesReducer from "./releaseNotesReducer";
import logsReducer from "./logsReducer";
import chartsReducer from "./chartsReducer";


const appReducer = combineReducers({
    userReducer: userReducer,
    plansReducer: plansReducer,
    messageReducer: messageReducer,
    releaseNotesReducer: releaseNotesReducer,
    logsReducer: logsReducer,
    chartsReducer: chartsReducer
});

const rootReducer = (state, action) => {
    return appReducer(state, action)
};

export default rootReducer;