import React, {lazy, Suspense} from 'react';
import {ROUTE_PATHS, SEO_TAGS} from "../../../lib/constants";
import {Helmet} from "react-helmet";
const CheckerLazyHelper = lazy(() => import('./CheckerLazyHelper'));

const CheckerPage =(props)=>  {
    const {messageGroup} = props;

    return (
        <React.Fragment>
            <Helmet>
                <title>{SEO_TAGS.checker.title}</title>
                <meta name="description" content={SEO_TAGS.checker.description}/>
                <link rel="canonical" href={process.env.PUBLIC_URL + '/' + ROUTE_PATHS['checker']}/>
                <meta property="og:title"  content={SEO_TAGS.checker.title}/>
                <meta property="og:description" content={SEO_TAGS.checker.description}/>
                <meta property="og:url" content={process.env.PUBLIC_URL + '/' + ROUTE_PATHS['checker']}/>
                <meta name="twitter:title" content={SEO_TAGS.checker.title}/>
                <meta name="twitter:description" content={SEO_TAGS.checker.description}/>
            </Helmet>
            <Suspense fallback={<div style={{height:"100vh"}}/>}>
                <CheckerLazyHelper messageGroup={messageGroup} />
            </Suspense>
        </React.Fragment>

    );
};

export default CheckerPage;
