import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {sendGaEvent} from "../../lib/utils";
import {GA_EVENTS} from "../../lib/constants";
import finaploLogoText from "../../../assets/img/finaplo-logo-text.svg";
import aplonhubLogoText from "../../../assets/img/aplonhub-logo-text.svg";
import finaploAiLogoText from "../../../assets/img/finaplo-ai-logo-text.svg";

export const SubMenu =(props)=> {
    const {nav, currentPath} = props;
    const [currPath, setCurrPath] = useState(null);

    useEffect(()=> {
        setCurrPath(currentPath);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currPath]);

    const renderSubMenuLogoTxtImg =(type)=> {
        switch (type) {
            case 'FINaplo':
                return <img src={finaploLogoText} alt="FINaplo Financial Services logo and trademark"
                            style={{marginLeft: "-4px"}}
                            className='finaplo__logo--img'
                            width="225" height="16" loading="eager"/>
            case 'FINaploAI':
                return <img src={finaploAiLogoText} alt="FINaplo.AI logo"
                            className='finaplo-ai__logo--img'
                            width="82" height="18" loading="eager"/>
            case 'aplonHUB':
                return <img src={aplonhubLogoText} alt="aplonhub logo"
                            className='aplonhub__logo--img'
                            width="98" height="22" loading="eager"/>
            default:
                return <React.Fragment/>;
        }
    }

    const renderSubMenuLogoTmImg =(type)=> {
        switch (type) {
            case 'FINaplo':
                return <div className="product__tm product__tm--finaplo">
                            <div className="bg-mn bg-tm">
                            </div>
                        </div>
            case 'FINaploAI':
                return <div className="product__tm product__tm--finaplo-ai">
                            <div className="bg-mn bg-ai">
                            </div>
                        </div>
            case 'aplonHUB':
                return <div className="product__tm product__tm--aplonhub">
                            <div className="bg-mn bg-hub">
                            </div>
                        </div>
            default:
                return <React.Fragment/>;
        }
    }

    return (
        <section className={'nav__with-sub--menu ' + nav.linkSource}>
            {
                nav.submenu.map((subItem, index)=> {
                    return <article key={'hdr-article-' + index}
                                    className={'submenu__article--' + nav.linkSource + (subItem.subtitle ? ' submenu__article--products__' + subItem.subtitle.toLowerCase() : '')}>
                                {
                                    subItem.subtitle ?
                                        <p>
                                            {
                                                subItem.subtitle ?
                                                    renderSubMenuLogoTmImg(subItem.subtitle)
                                                    : null
                                            }
                                        </p> : null
                                }
                                <p>
                                    {
                                        subItem.subtitle ?
                                            renderSubMenuLogoTxtImg(subItem.subtitle)
                                        :null
                                    }
                                    <div className={'submenu__content ' + nav.linkSource}>
                                        {
                                            subItem.content.map((linkItem, idx)=> {
                                                return linkItem.route ?
                                                    <Link className={linkItem.cls}
                                                        to={{pathname:
                                                        linkItem.route,
                                                        state: {
                                                            from: currPath,
                                                            linkSource: nav.linkSource
                                                        },
                                                        hash: linkItem.hash}}
                                                           key={'hdr-link-' + idx}
                                                           onClick={()=> {
                                                               sendGaEvent({category: 'header_internal_link',
                                                                   action: GA_EVENTS.menu[nav.linkSource][linkItem.ga]}
                                                               )}}>
                                                        <h6>{linkItem.title}</h6>
                                                        { linkItem.text ?
                                                            <p>
                                                                {linkItem.text}
                                                            </p>
                                                        :null}
                                                    </Link>:
                                                    <a className={linkItem.cls}
                                                       href={linkItem.link}
                                                       key={'hdr-link-' + idx}
                                                       rel='noopener noreferrer'
                                                       target='_blank'
                                                       onClick={()=> {
                                                           sendGaEvent({category: 'header_external_link',
                                                               action: GA_EVENTS.menu[nav.linkSource][linkItem.ga]}
                                                           )}}>
                                                        <h6>{linkItem.title}</h6>
                                                        <p>{linkItem.text}</p>
                                                    </a>
                                            })
                                        }
                                    </div>
                                </p>
                            </article>
                })
            }
        </section>
    );
}

export default SubMenu;
