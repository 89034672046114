import {makeRequest} from "../api/api-client";


export const initToken = (path) => {
    if (process.env.NODE_ENV === "production"){
        path = process.env.SERVER_URL + path;
    }
    return makeRequest(path, "POST", null, {}, "INIT_TOKEN");
};

export const registerUser = (path, obj) => {
    if (process.env.NODE_ENV === "production"){
        path = process.env.SERVER_URL + path;
    }
    const config = { headers: {'Content-Type': 'application/json'}};
    return makeRequest(path, "POST", obj, config, "REGISTER_USER");
};

export const confirmUser = (path, params) => {
    if (process.env.NODE_ENV === "production"){
        path = process.env.SERVER_URL + path;
    }
    const config = { headers: {'Content-Type': 'application/x-www-form-urlencoded'}, params: params};
    return makeRequest(path, "POST", null, config, "CONFIRM_USER");
};

export const resendConfirmationEmail = (path, email) => {
    if (process.env.NODE_ENV === "production"){
        path = process.env.SERVER_URL + path;
    }
    const config = { headers: {'Content-Type': 'application/x-www-form-urlencoded'}, params: {email: email}};
    return makeRequest(path, "POST", null, config, "RESEND_CONFIRMATION_EMAIL");
};

export const loginUser = (path, obj) => {
    if (process.env.NODE_ENV === "production"){
        path = process.env.SERVER_URL + path;
    }
    const config = { headers: {'Content-Type': 'application/json'}};
    return makeRequest(path, "POST", obj, config, "LOGIN_USER");
};

export const logoutUser = (path) => {
    if (process.env.NODE_ENV === "production"){
        path = process.env.SERVER_URL + path;
    }
    return makeRequest(path, "POST", null, {}, "LOGOUT_USER");
};

export const sendResetPasswordEmail = (path, email) => {
    if (process.env.NODE_ENV === "production"){
        path = process.env.SERVER_URL + path;
    }
    const config = { headers: {}, params: {email: email}};
    return makeRequest(path, "GET", null, config, "SEND_RESET_PASSWORD_EMAIL");
};

export const sendNewPassword = (path,  obj) => {
    if (process.env.NODE_ENV === "production") {
        path = process.env.SERVER_URL + path;
    }
    const config = {headers: {'Content-Type': 'application/json'}};
    return makeRequest(path, "POST", obj, config, "SEND_NEW_PASSWORD");
};

export const disconnectActiveSession = (path) => {
    if (process.env.NODE_ENV === "production") {
        path = process.env.SERVER_URL + path;
    }
    return makeRequest(path, "POST",null, {}, "DISCONNECT_ACTIVE_SESSION");
};

export const loginUserLinkedin = (path) => {
    if (process.env.NODE_ENV === "production"){
        path = process.env.SERVER_URL + path;
    }
    const config = { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
    return makeRequest(path, "GET", null, config, "LOGIN_USER_LINKEDIN");
};

export const confirmUserLinkedin = (path, params) => {
    if (process.env.NODE_ENV === "production"){
        path = process.env.SERVER_URL + path;
    }
    const config = {params: params};
    return makeRequest(path, "GET", null, config, "CONFIRM_USER_LINKEDIN");
};