import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'text/plain';
axios.interceptors.request.use(function (config) {
    if (process.env.NODE_ENV !== 'production') {
        console.debug("AXIOS Request " + JSON.stringify(config));
    }
    config.withCredentials = true;
    return config
}, function (error) {
    console.error("AXIOS Request Error " + JSON.stringify(error));
    return Promise.reject(error);
});

export const makeRequest = (url, method, params, config, reducer) => {
    switch (method) {
        case "POST" : {
            return {
                type: reducer,
                payload: axios.post(url, params, config)
            }
        }
        case "PUT" : {
            return {
                type: reducer,
                payload: axios.put(url, params, config)
            }
        }
        case "GET" : {
            return {
                type: reducer,
                payload: axios.get(url, config)
            }
        }
        case "DELETE" : {
            return {
                type: reducer,
                payload: axios.delete(url, config)
                //     headers: headers,
                //     data: params
                // })
            }
        }
        default:
            break;
    }
};