import React, {lazy, Suspense} from 'react';
import {Helmet} from "react-helmet";
import {SEO_TAGS} from "../../../../../lib/constants";
const LoginLazyHelper = lazy(() => import('./LoginLazyHelper'));

const LoginPage =(props)=>  {
    const {update} = props;
    return (
        <React.Fragment>
            <Helmet>
                <title>{SEO_TAGS.login.title}</title>
                <meta name="description" content={SEO_TAGS.login.description}/>
                <link rel="canonical" href={process.env.PUBLIC_URL + '/login'}/>
                <meta property="og:title"  content={SEO_TAGS.login.title}/>
                <meta property="og:description" content={SEO_TAGS.login.description}/>
                <meta property="og:url" content={process.env.PUBLIC_URL + '/login'}/>
                <meta name="twitter:title" content={SEO_TAGS.login.title}/>
                <meta name="twitter:description" content={SEO_TAGS.login.description}/>
            </Helmet>
            <Suspense fallback={<div style={{height:"100vh"}}/>}>
                <LoginLazyHelper update={update}/>
            </Suspense>
        </React.Fragment>
    );
};

export default LoginPage;
