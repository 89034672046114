import React, {lazy, Suspense} from 'react';
const ConfirmLinkedinLazyHelper = lazy(() => import('./ConfirmLinkedinLazyHelper'));

const ConfirmLinkedinPage =(props)=>  {
    const {update} = props;
    return (
        <Suspense fallback={<div style={{height:"100vh"}}/>}>
            <ConfirmLinkedinLazyHelper update={update} />
        </Suspense>
    );
};

export default ConfirmLinkedinPage;
