import React, {lazy, Suspense, useEffect, useState} from "react";
import "swagger-ui-react/swagger-ui.css";
import {isMobile} from 'mobile-device-detect';
import store from "../../../store";
import {swaggerRequest} from "../../../providers/finMessageRequest";
import {Helmet} from "react-helmet";
import {ROUTE_PATHS, SEO_TAGS} from "../../../lib/constants";
import {useSelector} from "react-redux";
const ApiInstructions =  lazy(() => import('./ApiInstructionsLazyHelper'));
const ApiCallToAction =  lazy(() => import('./ApiCallToAction'));
const ApiFaq =  lazy(() => import('./ApiFaq'));
const ApiSwagger =  !isMobile ? lazy(() => import('./ApiSwagger')) : null;
const ApiQuestion =  lazy(() => import('./ApiQuestion'));


const ApiPage = () => {
    const [swagData, setSwagData] = useState(null);
    const [dataFailure, setDataFailure] = useState(false);

    const user = useSelector(state => state.userReducer);

    useEffect(()=> {
        window.scrollTo(0, 0);
    },[]);

    useEffect(()=> {
        if (!(isMobile && !user.userLoggedIn)) {
            store.dispatch(swaggerRequest()).then((response) => {
                setSwagData(response.value.data);
            }, (err)=> {
                setDataFailure(true);
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isMobile, user.userLoggedIn]);


    return (
        <React.Fragment>
            <Helmet>
                <title>{SEO_TAGS.api.title}</title>
                <meta name="description" content={SEO_TAGS.api.description}/>
                <link rel="canonical" href={process.env.PUBLIC_URL + '/' + ROUTE_PATHS['api']}/>
                <meta property="og:title"  content={SEO_TAGS.api.title}/>
                <meta property="og:description" content={SEO_TAGS.api.description}/>
                <meta property="og:url" content={process.env.PUBLIC_URL + '/' + ROUTE_PATHS['api']}/>
                <meta name="twitter:title" content={SEO_TAGS.api.title}/>
                <meta name="twitter:description" content={SEO_TAGS.api.description}/>
            </Helmet>
            <div className="checker-api">
                <div className="checker-api__content" style={{width: "100%",  height: "100%", minHeight: "1355px"}}>
                    <Suspense fallback={<div style={!isMobile ? {width: "50%", height: "490px"} : {width: "100%", height: "565px"}}/>}>
                        <ApiInstructions swagData={swagData} />
                    </Suspense>
                    <Suspense fallback={<div style={!isMobile ? {width: "50%", height: "266px"} : {width: "100%", height: "266px"}}/>}>
                        <ApiCallToAction />
                    </Suspense>
                    <Suspense fallback={<div style={!isMobile ? {width: "50%", height: "384px"} : {width: "100%", height: "384px"}}/>}>
                        <ApiFaq />
                    </Suspense>
                    {!isMobile ?
                        <Suspense fallback={<div style={{width: "50%", height: "100%"}}/>}>
                            <ApiSwagger swagData={swagData} dataFailure={dataFailure} />
                        </Suspense>
                   :null}
                </div>
                <Suspense fallback={<div style={{width: "100%", height: "140px"}}/>}>
                    <ApiQuestion />
                </Suspense>
            </div>
        </React.Fragment>
    )
}


export default ApiPage;