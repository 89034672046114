import React from 'react';
import {sendGaEvent} from "../../lib/utils";
import {APLON_HUB_LINK, APLON_HUB_PLAYLIST, GA_EVENTS} from "../../lib/constants";
import aplonhubScreen from "../../../assets/img/aplonhub-screen.webp";
import aplonhubLogo from "../../../assets/img/aplonhub-logo.svg";

const AplonHub =(props)=> {
    const {title, gaEvt} = props;
    return (
        <section className='aplonhub'>
            <h4>{title}</h4>
            <article className="aplonhub__container">
                <div className="aplonhub__content">
                    <div className="aplonhub__showcase">
                        <img src={aplonhubLogo}
                             width="213"
                             height="54"
                             alt="aplonhub product logo"
                             className="aplonhub__showcase--logo">
                        </img>
                        <img src={aplonhubScreen}
                             width="522"
                             height="319"
                            alt="aplonhub product screenshot"
                            className="aplonhub__showcase--screenshot">
                        </img>
                        <a href={APLON_HUB_PLAYLIST}
                           className="aplonhub__showcase--link-to btn__generic--reverse-clr"
                           rel="noopener noreferrer"
                           target="_blank"
                           onClick={()=> {
                               sendGaEvent({category: 'aplonhub_playlist_link',
                                   action: GA_EVENTS.aplonhub[gaEvt].playlist}
                               )}}>
                            <i/>
                            <span>{'aplonHUB Videos'}</span>
                        </a>
                    </div>
                    <div className="aplonhub__features">
                        <div style={{height: '40px'}}></div>
                        <ul>
                            <li>
                                <i className='bg-sprites bg-tick' />
                                <span>
                                    Everything is preconfigured and ready to run. You just choose the payment schemes that cover your messaging needs
                                </span>
                            </li>
                            <li>
                              <i className='bg-sprites bg-tick' />
                              <span>
                                    Easily connect with the world. We help you integrate with the core system, AML, and with any of your payment rails.
                              </span>
                            </li>
                            <li>
                                <i className='bg-sprites bg-tick' />
                                <span>
                                    Support your technology strategy. It can be installed either on-premises or in the cloud environment of your choosing.
                               </span>
                            </li>
                        </ul>
                        <a href={APLON_HUB_LINK}
                           className="aplonhub__showcase--link-to btn__generic--reverse-clr"
                           rel="noopener noreferrer"
                           target="_blank"
                           onClick={()=> {
                               sendGaEvent({category: 'aplonhub_product-link',
                                   action: GA_EVENTS.aplonhub[gaEvt].product}
                               )}}>
                            <span>{'More on aplonHUB'}</span>
                        </a>
                    </div>
                </div>
            </article>
        </section>
    );
}

export default AplonHub;
