import React from 'react';
import iso20022GuideComparison from "../../../assets/img/iso20022-guide_comparison.webp";
import iso20022GuideDetail from "../../../assets/img/iso20022-guide_detail.svg";
import {pdfGuide} from "../../lib/constants";


const ISO20022Guide =()=> {
    return (
        <section className='iso20022-guide'>
            <div className='iso20022-guide__container'>
                <div className='iso20022-guide__img--wrapper'>
                    <img src={iso20022GuideComparison}
                         width="360"
                         height="510"
                         className='iso20022-guide__img--comparison'
                         alt='a matrix comparing iso20022 development requirements' />
                    <img src={iso20022GuideDetail}
                         width="161"
                         height="153"
                         className='iso20022-guide__img--detail'
                         alt='zoomed in detail of the matrix' />
                </div>
                <article>
                    <h5>3 different approaches in implementing an ISO20022 Messaging Application</h5>
                    <p>Using an SDK could save you 8 months of development. See how you can achieve this!</p>
                    {pdfGuide('homepage', 'Donwload Free PDF', 'btn__generic--blue-hover')}
                </article>
            </div>
        </section>
    );
}

export default ISO20022Guide;
