export default function chartsReducer(state = {
    messagesTypeCount: {
        fetching: false,
        fetched: false
    },
    messagesTypeCurrency: {
        fetching: false,
        fetched: false
    }
}, action) {
    switch (action.type) {
        case "GET_MESSAGES_TYPE_COUNT_PENDING": {
            return {
                ...state,
                messagesTypeCount: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_MESSAGES_TYPE_COUNT_REJECTED": {
            return {
                ...state,
                messagesTypeCount: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_MESSAGES_TYPE_COUNT_FULFILLED": {
            return {
                ...state,
                messagesTypeCount: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "GET_MESSAGES_TYPE_CURRENCY_PENDING": {
            return {
                ...state,
                messagesTypeCurrency: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_MESSAGES_TYPE_CURRENCY_REJECTED": {
            return {
                ...state,
                messagesTypeCurrency: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_MESSAGES_TYPE_CURRENCY_FULFILLED": {
            return {
                ...state,
                messagesTypeCurrency: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        default : {
            return state
        }
    }
}