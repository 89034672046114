import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {AppContext} from '../../../App';
import {sendGaEvent, showSubHeader} from "../../lib/utils";
import {logoutUser} from "../../providers/authService";
import store from "../../store";
import {Link} from "react-router-dom";
import AnimateOnChange from "react-animate-on-change";
import {setUserPermission} from "../../actions/actions";
import {GA_EVENTS, ROUTE_PATHS, SUBHEADER_VALUES} from "../../lib/constants";
import moment from 'moment';
import {isMobile} from "mobile-device-detect";

const SubHeaderComponent = (props) =>  {
    const {location, mainCls} = props;
    const {state, dispatch} = useContext(AppContext);
    const reduxDispatch = useDispatch();
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [userEmail, setUserEmail] = useState(null);
    const [userSubscription, setUserSubscription] = useState(null);
    const [timeLeft, setTimeLeft] = useState(null);
    const [remainingTrials, setRemainingTrials] = useState(null);
    const [trialsCopy, setTrialsCopy] = useState(null);
    const [animate, setAnimate] = useState(false);
    const [atRegister, setAtRegister] = useState(false);
    const [atLogin, setAtLogin] = useState(false);
    const [pathName, setPathName] = useState(null);
    const [subTitle, setSubTitle] = useState(null);
    const [tagline, setTagline] = useState(null);
    const [showAuthBtns, setShowAuthBtns] = useState(false);
    const [hideLeftPart, setHideLeftPart] = useState(false);
    const  user = useSelector(state => state.userReducer);


    useEffect(()=> {
        if (location && location.pathname) {
            setPathName(location.pathname);
        }
        const pathName = location.pathname;
        let subHeaderObj =  showSubHeader(pathName);
        let subHeaderTitleTxt = subHeaderObj? subHeaderObj.title : null;
        let taglineTxt = subHeaderObj ? subHeaderObj.description : null;
        let showAuth = subHeaderTitleTxt === SUBHEADER_VALUES['checker'].title || subHeaderTitleTxt === SUBHEADER_VALUES['api'].title;
        let hideSubHeaderLeftPart =  (pathName.includes(ROUTE_PATHS['checker']) || pathName.includes(ROUTE_PATHS['api'])) && isMobile;
        setSubTitle(subHeaderTitleTxt);
        setTagline(taglineTxt);
        setShowAuthBtns(showAuth);
        setHideLeftPart(hideSubHeaderLeftPart);
    }, [location, location.pathname]);


    useEffect(() =>{
        if (!user.profile.fetching) {
            setUserLoggedIn(user.userLoggedIn);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.userLoggedIn]);

    useEffect(() =>{
        let usrPerm = defineUserPermissions(user, remainingTrials);
        if (usrPerm) {
            reduxDispatch(setUserPermission(usrPerm));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.profile, remainingTrials, user.initToken]);


    useEffect(() =>{
        setAtRegister(props.location.pathname.includes('/register'));
        setAtLogin(props.location.pathname.includes('/login'));
    },[user.register, props.location]);


    useEffect(() =>{
        if (user.profile && user.profile.data) {
            let subs = user.profile.data.subscriptions;
            let activeSub = subs[0];
            let expirationObj = calculateTimeLeft(activeSub.expiredOn);
            let trials = !expirationObj.expired ? (activeSub.planName !== 'Unlimited' ? activeSub.validationCounter : 999999) : 0;
            dispatch({ ...state, remainingTrials: trials, noChecksFlag: trials <= 0});
            setRemainingTrials(trials);
            setTrialsCopy(trials);
            setUserSubscription(activeSub);
            setUserEmail(user.profile.data.email);
            checkRemainingTrials(trials);
            const interval = setInterval(() => {
                checkExpiration(activeSub.expiredOn, interval);
            }, 60000);
            checkExpiration(activeSub.expiredOn, interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.profile]);


    useEffect(() => {
        if (state.remainingTrials && user.profile && user.profile.data) {
            let subs = user.profile.data.subscriptions;
            let activeSub = subs[0];
            let expirationObj = calculateTimeLeft(activeSub.expiredOn);
            let trials = checkUnlimitedTrialValue(state.remainingTrials, expirationObj.expired);
            setRemainingTrials(trials);
            checkRemainingTrials(trials);
            setTimeout(() => {
                setTrialsCopy(trials);
            }, 800);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[state.remainingTrials]);

    useEffect(() =>{
            setAnimate(state.animateTrials);
            setTimeout(() => {
                setAnimate(false);
            }, 1000);
    },[state.animateTrials, state.remainingTrials]);

    const checkExpiration = (expiredOn, interval) => {
        let expirationObj = calculateTimeLeft(expiredOn);
        setTimeLeft(expirationObj.timeleft);
        if (expirationObj.expired) {
            dispatch({ ...state, remainingTrials: 0,  noChecksFlag: true});
            setRemainingTrials(0);
            setTrialsCopy(0);
            clearInterval(interval);
        }
    };

    const checkRemainingTrials = (trials) => {
        dispatch({...state,
            noChecksFlag: trials <= 0
        });
    };

    const checkUnlimitedTrialValue = (trials, expired) => {
        return trials === "Unlimited" ? (!expired ? 999999 : 0): trials;
    };

    const LogoutUser = () => {
        const path = "/signout";
        store.dispatch(logoutUser(path)).then(()=> {
            dispatch({...state,
                remainingTrials: null,
                noChecksFlag: false
            })
        }, (error)=> {
            console.log(error.response)
        });
    };

    const calculateTimeLeft =(endDate)=> {
        const difference = moment(endDate) -  moment();
        let timeLeft = {};
        let hasExpired = false;
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
            };
        } else {
            timeLeft = {
                days: "00",
                hours: "00",
                minutes: "00",
            };
            hasExpired = true;
        }
        return {timeleft: timeLeft.days + "d:" + timeLeft.hours + "h:" + timeLeft.minutes + "m", expired: hasExpired}
    }

    const defineUserPermissions = (user, trials) => {
        let expirationObj = user.profile && user.profile.data ? calculateTimeLeft(user.profile.data.subscriptions[0].expiredOn): null;
        switch(true) {
            case user.userLoggedIn && user.profile && user.profile.data &&
            user.profile.data.subscriptions && user.profile.data.subscriptions[0] &&
            user.profile.data.subscriptions[0]['planName'] === 'Free' &&
            (trials > 0 && (expirationObj && !expirationObj.expired)):
                return 'freeActive';
            case user.userLoggedIn && user.profile && user.profile.data &&
            user.profile.data.subscriptions && user.profile.data.subscriptions[0] &&
            user.profile.data.subscriptions[0]['planName'] === 'Unlimited' &&
            (expirationObj && !expirationObj.expired):
                return 'unlimitedActive';
            case user.userLoggedIn && user.profile && user.profile.data &&
            user.profile.data.subscriptions && user.profile.data.subscriptions[0] &&
            user.profile.data.subscriptions[0]['planName'] === 'Free' &&
            (trials <= 0 || (expirationObj && expirationObj.expired)):
                return 'freeInactive';
            case user.userLoggedIn && user.profile && user.profile.data &&
            user.profile.data.subscriptions && user.profile.data.subscriptions[0] &&
            user.profile.data.subscriptions[0]['planName'] === 'Unlimited' &&
            (expirationObj && expirationObj.expired):
                return 'unlimitedInactive';
            case !user.userLoggedIn && !user.profile.fetched && !user.profile.fetching:
                return 'unAuth';
            default:
                return null;
        }
    }

    return (
        <React.Fragment>
            {subTitle && tagline ?
                <section className="subheader">
                    <div className="subheader__content">
                        {!hideLeftPart ?
                        <div className={'left__part' + (mainCls ? ' subheader__left-part--'  + mainCls : '')}>
                            <hgroup>
                                <h1>
                                    {subTitle}
                                </h1>
                                <h2>
                                    {tagline}
                                </h2>
                            </hgroup>
                        </div>:null}
                        {showAuthBtns ?
                            <div className={('right__part subheader__auth--wrapper subheader__auth--wrapper') + (userLoggedIn ? '__authed' : '__unauthed')}>
                                {!userLoggedIn ?
                                    <div className="subheader__auth">
                                        {!atLogin ?
                                            <Link to={{pathname: '/login', state: {from: pathName}}}
                                                  className="subheader__auth--link subheader__auth--link__login"
                                                  onClick={()=> {
                                                      sendGaEvent({category: 'subheader_internal_link',
                                                          action: GA_EVENTS.submenu.login[pathName.substring(1)]}
                                                      )}}>
                                                <span>Login</span>
                                            </Link>:null}
                                        {!atRegister ?
                                        <Link to={{pathname: '/register', state: {from: pathName}}}
                                              className="subheader__auth--link subheader__auth--link__signup"
                                              onClick={()=> {
                                                  sendGaEvent({category: 'subheader_internal_link',
                                                      action: GA_EVENTS.submenu.register[pathName.substring(1)]}
                                                  )}}>
                                            <span>Sign up</span>
                                        </Link>:null}
                                    </div>:
                                    <React.Fragment>
                                        { userSubscription
                                            && userEmail
                                            && timeLeft
                                            && remainingTrials !== null ?
                                        <div className="subheader__profile">
                                            <div className="subheader__profile--subscription">
                                                <div className={"subheader__profile--plan" +
                                                    (!state.noChecksFlag ? " subheader__profile--plan__active" : " subheader__profile--plan__inactive")}>
                                                    {!state.noChecksFlag ?
                                                        <span>
                                                            {userSubscription.planName + (userSubscription.planName === 'Free' ? ' TRIAL':'')}
                                                        </span>:
                                                        <React.Fragment>
                                                            {userSubscription.planName === 'Unlimited' ?
                                                            <React.Fragment>
                                                                <span>
                                                                    {userSubscription.planName + " "}
                                                                </span>
                                                                <span>
                                                                    {"EXPIRED"}
                                                                </span>
                                                            </React.Fragment>:
                                                            <React.Fragment>
                                                                <span>
                                                                    {"EXPIRED"}
                                                                </span>
                                                                <span>
                                                                    {` ${userSubscription.planName} `}
                                                                </span>
                                                                <span>
                                                                    {"TRIAL"}
                                                                </span>
                                                            </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </div>
                                                <div className="subheader__profile--email">
                                                    {userEmail}
                                                </div>
                                                <Link to={'/' + ROUTE_PATHS['pricing']}
                                                      className="subheader__profile--view-plans">
                                                    {"View plans"}
                                                </Link>
                                            </div>
                                            <div className={"subheader__profile--trials__container" +
                                                (!state.noChecksFlag ? " subheader__profile--trials__container--active":
                                                " subheader__profile--trials__container--inactive")}>
                                                    <AnimateOnChange
                                                        customTag="div"
                                                        baseClassName="subheader__profile--trials"
                                                        animationClassName="subheader__profile--trials--animate"
                                                        animate={animate}>
                                                    <div className="subheader__profile--trials__counter">
                                                        <AnimateOnChange
                                                            customTag="div"
                                                            baseClassName="subheader__profile--trials__counter__copy"
                                                            animationClassName="subheader__profile--trials__counter__copy--animate"
                                                            animate={animate}>
                                                            {trialsCopy < 900000 ? trialsCopy : '\u221e'}
                                                        </AnimateOnChange>
                                                        {remainingTrials < 90000 ?  remainingTrials :  '\u221e'}
                                                    </div>
                                                    <div className="subheader__profile--trials__remaining">
                                                        <span>{"REMAINING"}</span>
                                                        <span>{"ACTIONS"}</span>
                                                    </div>
                                                    <div className="subheader__profile--trials__expire">
                                                        <span className="subheader__profile--trials__expire--label">{"Expiration "}</span>
                                                        <span className="subheader__profile--trials__expire--countdown">{timeLeft}</span>
                                                    </div>
                                                </AnimateOnChange>
                                            </div>
                                            <div className="subheader__auth">
                                                <button className="subheader__auth--logout"
                                                        title="Sign out"
                                                        onClick={()=> {
                                                            LogoutUser()
                                                        }}>
                                                <span className="bg-logout bg-sprites"/>
                                                </button>
                                            </div>
                                        </div>:null}
                                    </React.Fragment>}
                            </div>:null}
                        </div>
                </section>
            :null}
        </React.Fragment>
    );
}

export const SubHeader = withRouter(SubHeaderComponent);
