import React, {lazy, Suspense} from 'react';
import {SEO_TAGS} from "../../../../../lib/constants";
import {Helmet} from "react-helmet";
const RegisterLazyHelper = lazy(() => import('./RegisterLazyHelper'));

const RegisterPage =()=>  {
    return (
        <React.Fragment>
            <Helmet>
                <title>{SEO_TAGS.register.title}</title>
                <meta name="description" content={SEO_TAGS.register.description}/>
                <link rel="canonical" href={process.env.PUBLIC_URL + '/register'}/>
                <meta property="og:title"  content={SEO_TAGS.register.title}/>
                <meta property="og:description" content={SEO_TAGS.register.description}/>
                <meta property="og:url" content={process.env.PUBLIC_URL + '/register'}/>
                <meta name="twitter:title" content={SEO_TAGS.register.title}/>
                <meta name="twitter:description" content={SEO_TAGS.register.description}/>
            </Helmet>
            <Suspense fallback={<div style={{height:"100vh"}}/>}>
                <RegisterLazyHelper/>
            </Suspense>
        </React.Fragment>

    );
};

export default RegisterPage;
