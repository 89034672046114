import React from 'react';
import {render} from 'react-dom';
import {App} from "./App";
import { Provider } from 'react-redux';
import {BrowserRouter as Router} from "react-router-dom";
import store from './js/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './assets/css/Main.css';

const rootElement = document.getElementById('root');
render(<Provider store={store}>
            <Router basename={'/'}>
                <App />
            </Router>
        </Provider>, rootElement)