import React, {useReducer, useEffect, useState, lazy, Suspense} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {useDispatch} from 'react-redux';
import ErrorBoundary from "./js/components/uiElements/ErrorBoundary";
import {initToken} from "./js/providers/authService";
import ReactGA from 'react-ga';
import gtag, { install } from 'ga-gtag';
import TagManager from 'react-gtm-module'
import update from 'immutability-helper';
import store from "./js/store";
import {getUserProfile} from "./js/providers/userService";
import {ROUTE_PATHS} from "./js/lib/constants";
import HomePage from "./js/components/pages/home/HomePage";
import CheckerPage from "./js/components/pages/checker/CheckerPage";
import ApiPage from "./js/components/pages/api/ApiPage";
import FinMessagesPage from "./js/components/pages/financialMessages/FinMessagesPage";
import PricingPage from "./js/components/pages/pricing/PricingPage"
import LoginPage from "./js/components/pages/checker/authentication/login/LoginPage";
import RegisterPage from "./js/components/pages/checker/authentication/register/RegisterPage";
import ConfirmLinkedinPage from "./js/components/pages/checker/authentication/confirm/linkedin/ConfirmLinkedinPage";
import ConfirmPage from "./js/components/pages/checker/authentication/confirm/native/ConfirmPage";
import TermsPage from "./js/components/pages/terms/TermsPage";
import Resources from "./js/components/pages/resources/ResourcesPage";
import ReleaseNotesPage from "./js/components/pages/resources/releaseNotes/ReleaseNotesPage";
import PaymentPage from "./js/components/pages/pricing/payment/PaymentPage";
import {showSubHeader} from "./js/lib/utils";
import {isMobile} from "mobile-device-detect";
import {Header} from "./js/components/layout/Header";
import {SubHeader} from "./js/components/layout/SubHeader";

const FooterLazyHelper = lazy(() => import("./js/components/layout/FooterLazyHelper"));

const DownloadLibrariesPage  = lazy(() => import('./js/components/crm/DownloadLibrariesPage'));
const QuoteLibrariesPage  =  lazy(() => import('./js/components/crm/QuoteLibrariesPage'));
const ThankYou =  lazy(() => import('./js/components/crm/ThankYou'));


export const AppContext = React.createContext();
const trackingId = "UA-1036997-1";

const tagManagerArgs = {
    gtmId: 'GTM-TT4B8DM'
}

if (!process.env.PUBLIC_URL.includes('uat.pc14.eu') && process.env.NODE_ENV === "production") {
    ReactGA.initialize(trackingId);
    TagManager.initialize(tagManagerArgs);
    install('G-K4SEQ1JPX4');
    gtag('config', 'G-K4SEQ1JPX4', {
        send_page_view: true
    });
}

const Stomp = require('stompjs');

const initialState = {
    noChecksFlag: false,
    remainingTrials: null,
    animateTrials: false,
    renderResetPw: false,
    emailParam: null,
    confirmationCodeParam: null,
    resetPwSent: false

};

function reducer(state, action) {
    return update(state, {
        noChecksFlag: {$set: action.noChecksFlag},
        remainingTrials: {$set: action.remainingTrials},
        animateTrials: {$set: action.animateTrials},
        renderResetPw: {$set: action.renderResetPw},
        emailParam: {$set: action.emailParam},
        confirmationCodeParam: {$set: action.confirmationCodeParam},
        resetPwSent: {$set: action.resetPwSent}
    });
}

const AppComponent = (props) =>  {
    const {location} = props;
    const [mainClsName, setMainClsName] = useState("main");
    const [showSubHdr, setShowSubHdr] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);
    const reduxDispatch = useDispatch();

    const client =  Stomp.client(process.env.SERVER_URL.replace("https://", "wss://").replace("http://", "ws://") + "/socket");

    useEffect(() => {
        store.dispatch(getUserProfile('/me')).then((response)=>{
            update(response.action.payload.data.email);
        });

        const getToken = () =>{
            reduxDispatch(initToken('/init'));
        };
        getToken();
        connect(client);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { pathname, hash} = location;
        let pathComponent = pathname.split('/');
        pathComponent[1] = !pathComponent[1].trim("") ? 'home' : (pathComponent[1] === ROUTE_PATHS['checker'] && pathComponent[2] ? pathComponent[2] : (pathComponent[2] && pathComponent[2].includes('reset') ? 'reset' : pathComponent[1])) ;
        let clsName = pathComponent[1];
        setMainClsName(clsName);
        setShowSubHdr((pathname && pathname !== '' && showSubHeader(pathname) && !(isMobile && pathname.includes('online'))));
        if (!process.env.PUBLIC_URL.includes('uat.pc14.eu') && process.env.NODE_ENV === "production") {
            ReactGA.set({page: pathname}); // Update the user's current page
            ReactGA.pageview(pathname + (hash ? hash : '')); // Record a pageview for the given page
            gtag('set', 'page_path', pathname + (hash ? hash : ''));
            gtag('event', 'page_view', {
                'page_location': process.env.PUBLIC_URL,
                'page_path': pathname + (hash ? hash : ''),
                'send_page_view': true
            })
        }
    }, [location]);


    const callback = (message) => {
        // called when the client receives a STOMP message from the server
        if (message.body) {
            console.log("got message with body " + message.body);
            if (message.body === 'PAYMENT.COMPLETE') {
                store.dispatch(getUserProfile('/me')).then((response)=>{
                    update(response.action.payload.data.email);
                });
            }
            if (message.body.startsWith('REMAINING_TRIALS')) {
                let remainingTrials = parseInt(message.body.replace('REMAINING_TRIALS: ', ''), 10);
                dispatch({...state, remainingTrials: remainingTrials});
            }
        } else {
            console.log("got empty message");
        }
    };

   const connect = () => {
        try {
            client.debug = null;
            client.connect({}, function (frame) {
                client.subscribe("/topic", callback);
                client.subscribe("/user/queue", callback);
            });
        } catch (error) {
            console.error(error)
        }
    };

    const update = (email) => {
        try {
            client.send("/app/connect", {}, JSON.stringify({
                "messageType": "CHANGE_SESSION_ID",
                "message": email
            }));
            client.subscribe("/user/queue", callback);
        } catch (error) {
            console.error(error)
        }
    };

  return (
      <React.Fragment>
         <AppContext.Provider value={{ state, dispatch }}>
             <Header key={'header'}/>
                 {showSubHdr ?
                    <SubHeader key={'subheader'}
                            update={update}
                            mainCls={mainClsName}
                        />
                 :null}
             <main className={'main__app main__' + mainClsName}>
                <ErrorBoundary>
                    <Switch>
                        <Route exact path='/' render={props => <HomePage {...props} />}/>
                        <Route exact path={'/register'} render={props => <RegisterPage {...props} />}/>
                        <Route exact path={'/login'} render={props => <LoginPage update={update} {...props} />}/>
                        <Route exact path={'/confirmation/:email/:confirmationCode'}
                               render={props => <ConfirmPage update={update} {...props} />}/>
                        <Route exact path={'/oauth2/oauth'}
                               render={props => <ConfirmLinkedinPage update={update} {...props} />}/>
                        <Route exact path={'/reset/password/:email/:confirmationCode'}
                               render={props => <LoginPage {...props} />} />
                        <Route exact path={'/pricing'}  component={PricingPage}/>
                        <Route exact path={'/api'} component={ApiPage} />
                        <Route exact path={'/financial-messages'} render={props => <FinMessagesPage {...props} />}/>
                        <Route exact path={'/resources'} render={props => <Resources {...props} />}/>
                        <Route exact path={'/release-notes/:project'} render={props => <ReleaseNotesPage {...props} />}/>
                        <Route exact path={'/terms'} component={TermsPage}/>
                        <Route exact path={'/online'} render={props => <CheckerPage messageGroup='mt' {...props} />}/>
                        <Route exact path={'/online/mt'} render={props => <CheckerPage messageGroup='mt' {...props} />}/>
                        <Route exact path={'/online/mt/:id'} render={props => <CheckerPage messageGroup='mt' {...props} />}/>
                        <Route exact path={'/online/mx'} render={props => <CheckerPage messageGroup='mx' {...props} />}/>
                        <Route exact path={'/online/mx/:id'} render={props => <CheckerPage messageGroup='mx' {...props} />}/>
                        <Route exact path={'/online/sepa'} render={props => <CheckerPage messageGroup='sepa' {...props} />}/>
                        <Route exact path={'/online/sepa/:id'} render={props => <CheckerPage messageGroup='sepa' {...props} />}/>
                        <Route exact path={'/online/cbpr/plus'} render={props => <CheckerPage messageGroup='cbprPlus' {...props} />}/>
                        <Route exact path={'/online/cbpr/plus/:id'} render={props => <CheckerPage messageGroup='cbprPlus' {...props} />}/>
                        <Route exact path={'/online/translator/cbpr/mt'} render={props => <CheckerPage messageGroup='translatorCbprMt' {...props} />}/>
                        <Route exact path={'/online/translator/cbpr/mt/:id'} render={props => <CheckerPage messageGroup='translatorCbprMt' {...props} />}/>
                        <Route exact path={'/online/translator/cbpr/mx'} render={props => <CheckerPage messageGroup='translatorCbprMx' {...props} />}/>
                        <Route exact path={'/online/translator/cbpr/mx/:id'} render={props => <CheckerPage messageGroup='translatorCbprMx' {...props} />}/>
                        <Route exact path={'/online/target2'} render={props => <CheckerPage messageGroup='targetTwo' {...props} />}/>
                        <Route exact path={'/online/target2/:id'} render={props => <CheckerPage messageGroup='targetTwo' {...props} />}/>
                        <Route exact path={'/online/sic/euroSic'} render={props => <CheckerPage messageGroup='sicEuroSic' {...props} />}/>
                        <Route exact path={'/online/sic/euroSic/:id'} render={props => <CheckerPage messageGroup='sicEuroSic' {...props} />}/>
                        <Route exact path={'/online/scrips/meps'} render={props => <CheckerPage messageGroup='scripsMeps' {...props} />}/>
                        <Route exact path={'/online/scrips/meps/:id'} render={props => <CheckerPage messageGroup='scripsMeps' {...props} />}/>
                        <Route exact path={'/online/translator/target2/mt'} render={props => <CheckerPage messageGroup='translatorTargetTwoMt' {...props} />}/>
                        <Route exact path={'/online/translator/target2/mt/:id'} render={props => <CheckerPage messageGroup='translatorTargetTwoMt' {...props} />}/>
                        <Route exact path={'/online/translator/target2/mx'} render={props => <CheckerPage messageGroup='translatorTargetTwoMx' {...props} />}/>
                        <Route exact path={'/online/translator/target2/mx/:id'} render={props => <CheckerPage messageGroup='translatorTargetTwoMx' {...props} />}/>
                        <Route exact path={'/online/fedNow'} render={props => <CheckerPage messageGroup='fedNow' {...props} />}/>
                        <Route exact path={'/online/fedNow/:id'} render={props => <CheckerPage messageGroup='fedNow' {...props} />}/>
                        <Route exact path={'/purchase-request'} render={props => <PaymentPage {...props} />}/>
                        <Route exact path={'/download-swift-mt'} render={props => (
                            <Suspense fallback={<div style={{height:"100vh"}}/>}>
                                <DownloadLibrariesPage {...props} />
                            </Suspense>
                        )}/>
                        <Route exact path={'/quote-messaging-libraries'} render={props => (
                            <Suspense fallback={<div style={{height:"100vh"}}/>}>
                                <QuoteLibrariesPage {...props} />
                            </Suspense>
                        )}/>
                        <Route exact path={'/thank-you'} render={props =>(
                            <Suspense fallback={<div style={{height:"100vh"}}/>}>
                                <ThankYou {...props} />
                            </Suspense>
                        )}/>
                        <Route path="/" render={()=> <Redirect to="/"/>}/>
                    </Switch>
                </ErrorBoundary>
            </main>
             <Suspense fallback={<div style={!isMobile ? {height:"240px",backgroundColor:"#003646"}:{height:"261px",backgroundColor:"#003646"} }/>}>
                 <FooterLazyHelper key={"footer"}/>
             </Suspense>
         </AppContext.Provider>
      </React.Fragment>
  );
};


export const App = withRouter(AppComponent);


