import React, {lazy, Suspense} from 'react';
import {ROUTE_PATHS, SEO_TAGS} from "../../../lib/constants";
import {Helmet} from "react-helmet";
const ResourcesLazyHelper = lazy(() => import('./ResourcesLazyHelper'));

const ResourcesPage =()=>  {
    return (
        <React.Fragment>
            <Helmet>
                <title>{SEO_TAGS.resources.title}</title>
                <meta name="description" content={SEO_TAGS.resources.description}/>
                <link rel="canonical" href={process.env.PUBLIC_URL + '/' + ROUTE_PATHS['resources']}/>
                <meta property="og:title"  content={SEO_TAGS.resources.title}/>
                <meta property="og:description" content={SEO_TAGS.resources.description}/>
                <meta property="og:url" content={process.env.PUBLIC_URL + '/' + ROUTE_PATHS['resources']}/>
                <meta name="twitter:title" content={SEO_TAGS.resources.title}/>
                <meta name="twitter:description" content={SEO_TAGS.resources.description}/>
            </Helmet>
            <Suspense fallback={<div style={{height:"100vh"}}/>}>
                <ResourcesLazyHelper/>
            </Suspense>
        </React.Fragment>
    );
}

export default ResourcesPage;
