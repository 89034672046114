import React, {lazy, Suspense} from 'react';
const TermsLazyHelper = lazy(() => import('./TermsLazyHelper'));

const TermsPage =()=>  {
    return (
        <React.Fragment>
            <Suspense fallback={<div style={{height:"100vh"}}/>}>
                <TermsLazyHelper/>
            </Suspense>
        </React.Fragment>
    );
};

export default TermsPage;
