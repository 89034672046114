import React, {lazy, Suspense} from 'react';
const ReleaseNotesLazyHelper = lazy(() => import('./ReleaseNotesLazyHelper'));

const ReleaseNotesPage =()=>  {
    return (
        <React.Fragment>
            <Suspense fallback={<div style={{height:"100vh"}}/>}>
                <ReleaseNotesLazyHelper/>
            </Suspense>
        </React.Fragment>
    );
};

export default ReleaseNotesPage;
