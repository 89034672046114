import {filterLogsResult, getLogId} from "../lib/logsFunc";

export default function logsReducer(state = {
    list: {

        fetching: false,
        fetched: false,
        payload: null
    },
    log: {

    }
}, action) {
    switch (true) {
        case action.type === "GET_LOGS_LIST_PENDING": {
            return {
                ...state,
                list: {
                    ...state.list,
                    fetching: true,
                    fetched: false
                }
            }
        }
        case action.type === "GET_LOGS_LIST_REJECTED": {
            return {
                ...state,
                list: {
                    fetching: false,
                    fetched: false,
                    payload: null
                }
            }
        }
        case action.type === "GET_LOGS_LIST_FULFILLED": {
            return {
                ...state,
                list: {
                    ...state.list,
                    fetching: false,
                    fetched: true,
                    payload: filterLogsResult(action.payload.data)
                }
            }
        }
        case action.type.startsWith("GET_LOG_") && action.type.endsWith(('PENDING')): {
            const id = getLogId(action.type);
            return {
                ...state,
                log: {
                    ...state.log,
                    [id]: {
                        fetching: true,
                        fetched: false
                    }
                }
            }
        }
        case action.type.startsWith("GET_LOG_") && action.type.endsWith(('REJECTED')): {
            const id = getLogId(action.type);
            return {
                ...state,
                log: {
                    ...state.log,
                    [id]: {
                        fetching: false,
                        fetched: false
                    }
                }
            }
        }
        case action.type.startsWith("GET_LOG_") && action.type.endsWith(('FULFILLED')): {
            const id = getLogId(action.type);
            return {
                ...state,
                log: {
                    ...state.log,
                    [id]: {
                        fetching: false,
                        fetched: true,
                        payload: action.payload.data
                    }
                }
            }
        }
        default : {
            return state
        }
    }
}