import React, {Suspense} from 'react';
import {APLONHUB_TITLE, SEO_TAGS} from "../../../lib/constants";
import Helmet from "react-helmet";
import {isMobile, isMobileOnly} from "mobile-device-detect";
import AplonHub from "../../uiElements/AplonHub";
import ISO20022Guide from "../../uiElements/ISO20022Guide";
const Hero  =  React.lazy(() => import('./Hero'));
const Benefits=  React.lazy(() => import('./Benefits'));
const InfoGroup =  React.lazy(() => import('./InfoGroup'));

const HomePage = () =>  {
    return (
        <div className='homepage'>
            <Helmet>
                <title>{SEO_TAGS.homepage.title}</title>
                <meta name="description" content={SEO_TAGS.homepage.description}/>
                <link rel="canonical" href={process.env.PUBLIC_URL}/>
                <meta property="og:title"  content={SEO_TAGS.homepage.title}/>
                <meta property="og:description" content={SEO_TAGS.homepage.description}/>
                <meta property="og:url" content={process.env.PUBLIC_URL}/>
                <meta name="twitter:title" content={SEO_TAGS.homepage.title}/>
                <meta name="twitter:description" content={SEO_TAGS.homepage.description}/>
            </Helmet>
            <React.Fragment>
                <Suspense fallback={<div style={!isMobile ? {height:"427px"}:(isMobileOnly ? {height:"340px"}:{height:"225px"})}/>}>
                    <Hero />
                </Suspense>
                <Suspense fallback={<div style={!isMobile ? {height:"2785px"}:(isMobileOnly ? {height:"1325px"}:{height:"1950"})}/>}>
                    <InfoGroup />
                </Suspense>
                <Suspense fallback={<div style={!isMobile ? {height:"670px"}:(isMobileOnly ? {height:"1470px"}:{height:"870px"})}/>}>
                    <Benefits />
                </Suspense>
                <Suspense fallback={<div style={!isMobile ? {height:"757px"}:(isMobileOnly ? {height:"1200px"}:{height:"1000px"})}/>}>
                    <AplonHub title={APLONHUB_TITLE.homepage} gaEvt={'homepage'} />
                </Suspense>
                <Suspense fallback={<div style={!isMobile ? {height:"689px"}:(isMobileOnly ? {height:"790px"}:{height:"695px"})}/>}>
                    <ISO20022Guide />
                </Suspense>
            </React.Fragment>
        </div>
    );
}

export default HomePage;
