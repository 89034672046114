// import axios from 'axios';
import {makeRequest} from "../api/api-client";


export const finMessageRequest = (path, reqBody, isPdf, type, fedNowParam, sepaEpcParam, rsnInfValParam) => {
    if (process.env.NODE_ENV === "production" ){
        path = process.env.SERVER_URL + path;
    }

    const config =  { headers: {'Content-Type': 'text/plain'}};

    if (isPdf) {
        config.responseType = 'blob';
    }

    const params = {
        'fedNowMessageType': fedNowParam,
        'sepaEpcMessageType': sepaEpcParam,
        'reasonInformationValue': rsnInfValParam
    };

    const definedParams = Object.keys(params).filter(key => params[key] !== undefined && params[key] !== null);

    const queryString = definedParams
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');

    console.log('lalala', queryString)

    path = queryString ? `${path}?${queryString}` : path;

    const reducer = setMessageReducer(path, type, isPdf);

    return makeRequest(path, "POST", reqBody, config, reducer);
};


const setMessageReducer = (path, type, isPdf) => {
    switch(true) {
        case (path.includes("mt") && type === "validate" && !isPdf):
            return "MT_VALIDATE";
        case (path.includes("mt") && type === "validate" && isPdf):
            return "MT_VALIDATE_PDF";
        case (path.includes("iso20022")):
            return "MX_VALIDATE";
        case (path.includes("sepa/epc-ct/validate") || path.includes("sepa/epc-dd/validate")):
            return "SEPA_VALIDATE";
        case (path.includes("sepa/epc-ct/return")):
            return "SEPA_RETURN";
        case (path.includes("sepa/epc-ct/recall/request")):
            return "SEPA_RECALL";
        case (path.includes("translator/cbpr/mt/to/mx")):
            return "CBPR_MT_TRANSLATE";
        case (path.includes("translator/cbpr/mx/to/mt")):
            return "CBPR_MX_TRANSLATE";
        case (path.includes("cbpr/validate")):
            return "CBPR_PLUS_VALIDATE";
        case (path.includes("cbpr/envelope")):
            return "CBPR_PLUS_ENVELOPE";
        case (path.includes("cbpr/return")):
            return "CBPR_PLUS_RETURN";
        case (path.includes("cbpr/recall/request")):
            return "CBPR_PLUS_RECALL";
        case (path.includes("cbpr/recall/response")):
            return "CBPR_PLUS_RECALL_RESPONSE";
        case (path.includes("translator/target2/mt/to/mx")):
            return "TARGET_TWO_MT_TRANSLATE";
        case (path.includes("translator/target2/mx/to/mt")):
            return "TARGET_TWO_MX_TRANSLATE";
        case (path.includes("/target2/validate")):
            return "TARGET_TWO_VALIDATE";
        case (path.includes("target2/return")):
            return "TARGET_TWO_RETURN";
        case (path.includes("target2/recall/request")):
            return "TARGET_TWO_RECALL";
        case (path.includes("target2/recall/response")):
            return "TARGET_TWO_RECALL_RESPONSE";
        case (path.includes("sic-eurosic/validate")):
            return "SIC_EUROSIC_VALIDATE";
        case (path.includes("scrips/validate")):
            return "SCRIPS_MEPS_VALIDATE";
        case (path.includes("scrips/envelope")):
            return "SCRIPS_MEPS_ENVELOPE";
        case (path.includes("fednow")):
            return "FEDNOW_VALIDATE";
        default:
            return "GET_MESSAGE";
    }
};

export const swaggerRequest = () => {
    let path = process.env.SWAGGER_PATH;
    if (process.env.NODE_ENV === "production" ) {
        path = process.env.SERVER_URL + path;
    }
    return makeRequest(path, "GET");
};

export const getApiKey = () => {
    let path = '/api/key/generate';
    if (process.env.NODE_ENV === "production" ) {
        path = process.env.SERVER_URL + path;
    }
    return makeRequest(path, "POST", {}, {}, "GET_API_KEY");
}

export const retrieveApiKey = () => {
    let path = '/api/key/retrieve/active';
    if (process.env.NODE_ENV === "production" ) {
        path = process.env.SERVER_URL + path;
    }
    return makeRequest(path, "GET", {}, {}, "RETRIEVE_API_KEY");
}
