import React, {useEffect, useState} from 'react';


function BurgerBtn(props) {
    const {menuIsOpen, toggleMenu} = props;
    const [isOpen, setIsOpen] = useState(false);


    useEffect(() => {
        setIsOpen(menuIsOpen)
    }, [menuIsOpen]);


    return (
        <div className='burger-btn__wrapper'>
            <div id="burger-menu-toggler" className={"burger-btn" + (isOpen ? " open" : "")}
                 onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
}

export default BurgerBtn;
