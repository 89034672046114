export default function plansReducer(state = {
    mt: {
        fetching: false,
        fetched: false
    },
    iso20022: {
        fetching: false,
        fetched: false
    },
    sepa: {
        fetching: false,
        fetched: false
    },
    translator: {
        fetching: false,
        fetched: false
    },
    translatorCbpr: {
        fetching: false,
        fetched: false
    },
    translatorTarget2: {
        fetching: false,
        fetched: false
    }
}, action) {
    switch (action.type) {
        case "GET_SWIFT_MT_RELEASE_NOTES_PENDING": {
            return {
                ...state,
                mt: {
                    fetching: true,
                    fetched: false
                }

            }
        }
        case "GET_SWIFT_MT_RELEASE_NOTES_REJECTED": {
            return {
                ...state,
                mt: {
                    fetching: false,
                    fetched: false
                }

            }
        }
        case "GET_SWIFT_MT_RELEASE_NOTES_FULFILLED": {
            return {
                ...state,
                mt: {
                    fetching: false,
                    fetched: true,
                    payload: action.payload.data
                }
            }
        }
        case "GET_ISO20022_RELEASE_NOTES_PENDING": {
            return {
                ...state,
                iso20022: {
                    fetching: true,
                    fetched: false
                }

            }
        }
        case "GET_ISO20022_RELEASE_NOTES_REJECTED": {
            return {
                ...state,
                iso20022: {
                    fetching: false,
                    fetched: false
                }

            }
        }
        case "GET_ISO20022_RELEASE_NOTES_FULFILLED": {
            return {
                ...state,
                iso20022: {
                    fetching: false,
                    fetched: true,
                    payload: action.payload.data
                }
            }
        }
        case "GET_SEPA_RELEASE_NOTES_PENDING": {
            return {
                ...state,
                sepa: {
                    fetching: true,
                    fetched: false
                }

            }
        }
        case "GET_SEPA_RELEASE_NOTES_REJECTED": {
            return {
                ...state,
                sepa: {
                    fetching: false,
                    fetched: false
                }

            }
        }
        case "GET_SEPA_RELEASE_NOTES_FULFILLED": {
            return {
                ...state,
                sepa: {
                    fetching: false,
                    fetched: true,
                    payload: action.payload.data
                }
            }
        }
        case "GET_TRANSLATOR_RELEASE_NOTES_PENDING": {
            return {
                ...state,
                translator: {
                    fetching: true,
                    fetched: false
                }

            }
        }
        case "GET_TRANSLATOR_RELEASE_NOTES_REJECTED": {
            return {
                ...state,
                translator: {
                    fetching: false,
                    fetched: false
                }

            }
        }
        case "GET_TRANSLATOR_RELEASE_NOTES_FULFILLED": {
            return {
                ...state,
                translator: {
                    fetching: false,
                    fetched: true,
                    payload: action.payload.data
                }
            }
        }
        case "GET_TRANSLATOR_CBPR_RELEASE_NOTES_PENDING": {
            return {
                ...state,
                translatorCbpr: {
                    fetching: true,
                    fetched: false
                }

            }
        }
        case "GET_TRANSLATOR_CBPR_RELEASE_NOTES_REJECTED": {
            return {
                ...state,
                translatorCbpr: {
                    fetching: false,
                    fetched: false
                }

            }
        }
        case "GET_TRANSLATOR_CBPR_RELEASE_NOTES_FULFILLED": {
            return {
                ...state,
                translatorCbpr: {
                    fetching: false,
                    fetched: true,
                    payload: action.payload.data
                }
            }
        }
        case "GET_TRANSLATOR_TARGET_TWO_RELEASE_NOTES_PENDING": {
            return {
                ...state,
                translatorTarget2: {
                    fetching: true,
                    fetched: false
                }

            }
        }
        case "GET_TRANSLATOR_TARGET_TWO_RELEASE_NOTES_REJECTED": {
            return {
                ...state,
                translatorTarget2: {
                    fetching: false,
                    fetched: false
                }

            }
        }
        case "GET_TRANSLATOR_TARGET_TWO_RELEASE_NOTES_FULFILLED": {
            return {
                ...state,
                translatorTarget2: {
                    fetching: false,
                    fetched: true,
                    payload: action.payload.data
                }
            }
        }
        default : {
            return state
        }
    }
}