import React, {lazy, Suspense} from 'react';
const ConfirmLazyHelper = lazy(() => import('./ConfirmLazyHelper'));

const ConfirmPage =(props)=>  {
    const {update} = props;
    return (
        <Suspense fallback={<div style={{height:"100vh"}}/>}>
            <ConfirmLazyHelper update={update} />
        </Suspense>
    );
};

export default ConfirmPage;
