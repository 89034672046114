export default function plansReducer(state = {
   payload: null,
    fetching: false,
    fetched: false
}, action) {
    switch (action.type) {
        case "GET_PLANS_PENDING": {
            return {
                ...state,
                fetching: true,
                fetched: false

            }
        }
        case "GET_PLANS_REJECTED": {
            return {
                ...state,
                fetching: false,
                fetched: false
            }
        }
        case "GET_PLANS_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                payload: action.payload.data
            }
        }
        default : {
            return state
        }
    }
}