import React from "react";
import {ROUTE_PATHS} from "./constants";
import moment from "moment";

export const getLogId =(actionType)=> {
    actionType = actionType.replace("GET_LOG_", "");
    actionType =  actionType.substr(0, actionType.lastIndexOf("_"));
    return actionType;
}

export const filterLogsResult =(logs)=> {
    const logsTableData = logs.content.map((log, idx)=> {
        return {
            index: idx + 1 + ((logs.pageable.pageNumber) * logs.pageable.pageSize),
            id: log.id,
            logEntryTime: log.logEntryTime,
            messageType: log.messageType.length > 26 || (!log.messageType || 0 === log.messageType.length || log.messageType.trim() === '') || log.messageType.toLowerCase() === 'unknown' ? 'Unknown' : log.messageType,
            message: log.message,
            action: log.action,
            level: log.action !== 'pdf' ? (log.level === "INFO" ? "Valid" : "Error") : 'Download',
            isApi: log.action.toUpperCase().startsWith('API')
        }
    });
    return {
        pageNumber: logs.pageable.pageNumber + 1,
        pageSize: logs.pageable.pageSize,
        totalElements: logs.totalElements,
        totalPages: logs.totalPages,
        sorted: logs.pageable.sort.sorted,
        content: logsTableData
    }
}

export const rowClasses = (row, rowIndex) => {
    return !row.isApi ? 'log-row-online' : 'log-row-api';
};

export const selectRow  = (nonSelectable) => {
    return {
        mode: 'radio',
        hideSelectColumn: true,
        clickToSelect: true,
        bgColor: '#5f637c',
        nonSelectable: nonSelectable
    }
};

export const logIdFormatter =(id)=> {
    const n = id.lastIndexOf('-');
    return <span className='log-cell-id' title={id}>{id.substring(n + 1)}</span>;
}

export const logDateTimeFormatter =(cell, row)=> {
    return (
        moment.utc(cell).local().format('DD/MM/YYYY HH:mm')
    );
}

export const logActionFormatter =(cell, row)=> {
    const isApi = cell.toUpperCase().startsWith('API');
    return (
        <span className={isApi ? 'log-cell-api' : ''}>
            {isApi ? cell.replace('apis-', 'API-'): cell }
        </span>
    );
}

export const logResultFormatter =(cell, row)=> {
    return (
        <div className={'log-cell-result log-cell-result--' + cell.toLowerCase()}>
            <span>{cell}</span>
        </div>
    );
}

export const logsSortProps = {
    headerFormatter: (column, index, {sortElement, filterElement}) => {
        const order = sortElement.props;
        return (
            <div className="logs-datetime__sort">
                <span>{column.text} </span>
                {order.children}
            </div>
        )
    }
};


export const arrangeSizePerPageList=(total)=> {
    switch (true) {
        case (total > 20 && total < 100):
            return [
                {
                    text: '10', value: 20
                },
                {
                    text: '25', value: 20
                },
                {
                    text: '50', value: 50
                }
            ];
        case (total >= 100):
            return [{
                text: '10', value: 20
            },
                {
                    text: '25', value: 50
                },
                {
                    text: '50', value: 50
                },
                {
                    text: '100', value: 100
                }];
        default:
            return [{
                text: '10', value: 20
            },
                {
                    text: '25', value: 50
                },
                {
                    text: '50', value: 50
                },
                {
                    text: '100', value: 100
                }];
    }
}

export const defineLogRoute =(action, logEntryTime, message, level, messageType, errorResp)=> {
    switch(true) {
        case (action.startsWith('validate-mt') || action.includes('pdf')):
            return {
                route: '/' + ROUTE_PATHS['checker'] + '/mt',
                title: 'Message type: ' + messageType + ' | Date: ' +  moment(logEntryTime).format('DD/MM/YYYY HH:mm'),
                message: message,
                ...(errorResp && {errorResp: respIsJSONStr(errorResp)}),
                isValid: level !== 'Error' ? 'valid' : 'invalid'
            };
        case action === 'mx' || action === 'validate-iso20022':
            return {
                route: '/' + ROUTE_PATHS['checker'] + '/mx',
                title: 'Message type: ' + messageType + ' | Date: ' +  moment(logEntryTime).format('DD/MM/YYYY HH:mm'),
                message: message,
                ...(errorResp && {errorResp: respIsJSONStr(errorResp)}),
                isValid: level !== 'Error' ? 'valid' : 'invalid'
            };
        case action.includes('sepa'):
            return {
                route: '/' + ROUTE_PATHS['checker'] + '/sepa',
                title: 'Message type: ' + messageType + ' | Date: ' +  moment(logEntryTime).format('DD/MM/YYYY HH:mm'),
                message: message,
                ...(errorResp && {errorResp: respIsJSONStr(errorResp)}),
                isValid: level !== 'Error' ? 'valid' : 'invalid'
            };
        case ((action.includes('cbpr') || action.includes('iso20022-cbpr')) && (action.includes('validate') || action.includes('evnelope'))):
            return {
                route: '/' + ROUTE_PATHS['checker'] + '/cbpr/plus',
                title: 'Message type: ' + messageType + ' | Date: ' +  moment(logEntryTime).format('DD/MM/YYYY HH:mm'),
                message: message,
                ...(errorResp && {errorResp: respIsJSONStr(errorResp)}),
                isValid: level !== 'Error' ? 'valid' : 'invalid'
            };
        case action.startsWith('mt-to-cbpr') || (action.startsWith('translate-mt-to-iso') && action.endsWith('-cbpr')):
            return {
                route: '/' + ROUTE_PATHS['checker'] + '/translator/cbpr/mt',
                title: 'Message type: ' + messageType + ' | Date: ' +  moment(logEntryTime).format('DD/MM/YYYY HH:mm'),
                message: message,
                ...(errorResp && {errorResp: respIsJSONStr(errorResp)}),
                isValid: level !== 'Error' ? 'valid' : 'invalid'
            };
        case action.includes('cbpr-to-mt'):
            return {
                route: '/' + ROUTE_PATHS['checker'] + '/translator/cbpr/mx',
                title: 'Message type: ' + messageType + ' | Date: ' +  moment(logEntryTime).format('DD/MM/YYYY HH:mm'),
                message: message,
                ...(errorResp && {errorResp: respIsJSONStr(errorResp)}),
                isValid: level !== 'Error' ? 'valid' : 'invalid'
            };
        case action.includes('validate') && (action.includes('target2')):
            return {
                route: '/' + ROUTE_PATHS['checker'] + '/target2',
                title: 'Message type: ' + messageType + ' | Date: ' +  moment(logEntryTime).format('DD/MM/YYYY HH:mm'),
                message: message,
                ...(errorResp && {errorResp: respIsJSONStr(errorResp)}),
                isValid: level !== 'Error' ? 'valid' : 'invalid'
            };
        default:
            return {
                route: '/' + ROUTE_PATHS['checker'] + '/mt',
                title: 'Message type: ' + messageType + ' | Date: ' +  moment(logEntryTime).format('DD/MM/YYYY HH:mm'),
                message: message,
                ...(errorResp && {errorResp: respIsJSONStr(errorResp)}),
                isValid: level !== 'Error' ? 'valid' : 'invalid'
            };
    }
}

const respIsJSONStr = (resp) => {
    if (/^[\],:{}\s]*$/.test(resp.replace(/\\["\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
        return JSON.parse(resp)

    } else {

        return [resp];

    }
}
