export default function messageReducer(state = {
    mtValidate: {
        fetching: false,
        fetched: false
    },
    mtValidatePdf: {
        fetching: false,
        fetched: false
    },
    mxValidate: {
        fetching: false,
        fetched: false
    },
    sepaValidate: {
        fetching: false,
        fetched: false
    },
    sepaReturn: {
        fetching: false,
        fetched: false
    },
    sepaRecall: {
        fetching: false,
        fetched: false
    },
    cbprMtTranslate: {
        fetching: false,
        fetched: false
    },
    cbprMxTranslate: {
        fetching: false,
        fetched: false
    },
    cbprPlusValidate: {
        fetching: false,
        fetched: false
    },
    cbprPlusEnvelope: {
        fetching: false,
        fetched: false
    },
    cbprPlusReturn: {
        fetching: false,
        fetched: false
    },
    cbprPlusRecall: {
        fetching: false,
        fetched: false
    },
    cbprPlusRecallResponse: {
        fetching: false,
        fetched: false
    },
    targetTwoValidate: {
        fetching: false,
        fetched: false
    },
    targetTwoReturn: {
        fetching: false,
        fetched: false
    },
    targetTwoRecall: {
        fetching: false,
        fetched: false
    },
    targetTwoRecallResponse: {
        fetching: false,
        fetched: false
    },
    sicEuroSicValidate: {
        fetching: false,
        fetched: false
    },
    scripsMepsValidate: {
        fetching: false,
        fetched: false
    },
    scripsMepsEnvelope: {
        fetching: false,
        fetched: false
    },
    targetTwoMtTranslate: {
        fetching: false,
        fetched: false
    },
    targetTwoMxTranslate: {
        fetching: false,
        fetched: false
    },
    fedNowValidate: {
        fetching: false,
        fetched: false
    }
}, action) {
    switch (action.type) {
        case "MT_VALIDATE_PENDING": {
            return {
                ...state,
                mtValidate: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "MT_VALIDATE_REJECTED": {
            return {
                ...state,
                mtValidate: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "MT_VALIDATE_FULFILLED": {
            return {
                ...state,
                mtValidate: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "MT_VALIDATE_PDF_PENDING": {
            return {
                ...state,
                mtValidatePdf: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "MT_VALIDATE_PDF_REJECTED": {
            return {
                ...state,
                mtValidatePdf: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "MT_VALIDATE_PDF_FULFILLED": {
            return {
                ...state,
                mtValidatePdf: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "MX_VALIDATE_PENDING": {
            return {
                ...state,
                mxValidate: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "MX_VALIDATE_REJECTED": {
            return {
                ...state,
                mxValidate: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "MX_VALIDATE_FULFILLED": {
            return {
                ...state,
                mxValidate: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "SEPA_VALIDATE_PENDING": {
            return {
                ...state,
                sepaValidate: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "SEPA_VALIDATE_REJECTED": {
            return {
                ...state,
                sepaValidate: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "SEPA_VALIDATE_FULFILLED": {
            return {
                ...state,
                sepaValidate: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "SEPA_RETURN_PENDING": {
            return {
                ...state,
                sepaReturn: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "SEPA_RETURN_REJECTED": {
            return {
                ...state,
                sepaReturn: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "SEPA_RETURN_FULFILLED": {
            return {
                ...state,
                sepaReturn: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "SEPA_RECALL_PENDING": {
            return {
                ...state,
                sepaRecall: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "SEPA_RECALL_REJECTED": {
            return {
                ...state,
                sepaRecall: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "SEPA_RECALL_FULFILLED": {
            return {
                ...state,
                sepaRecall: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "CBPR_MT_TRANSLATE_PENDING": {
            return {
                ...state,
                cbprMtTranslate: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "CBPR_MT_TRANSLATE_REJECTED": {
            return {
                ...state,
                cbprMtTranslate: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "CBPR_MT_TRANSLATE_FULFILLED": {
            return {
                ...state,
                cbprMtTranslate: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "CBPR_MX_TRANSLATE_PENDING": {
            return {
                ...state,
                cbprMxTranslate: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "CBPR_MX_TRANSLATE_REJECTED": {
            return {
                ...state,
                cbprMxTranslate: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "CBPR_MX_TRANSLATE_FULFILLED": {
            return {
                ...state,
                cbprMxTranslate: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "CBPR_PLUS_VALIDATE_PENDING": {
            return {
                ...state,
                cbprPlusValidate: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "CBPR_PLUS_VALIDATE_REJECTED": {
            return {
                ...state,
                cbprPlusValidate: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "CBPR_PLUS_VALIDATE_FULFILLED": {
            return {
                ...state,
                cbprPlusValidate: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "CBPR_PLUS_ENVELOPE_PENDING": {
            return {
                ...state,
                cbprPlusEnvelope: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "CBPR_PLUS_ENVELOPE_REJECTED": {
            return {
                ...state,
                cbprPlusEnvelope: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "CBPR_PLUS_ENVELOPE_FULFILLED": {
            return {
                ...state,
                cbprPlusEnvelope: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "CBPR_PLUS_RETURN_PENDING": {
            return {
                ...state,
                cbprPlusReturn: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "CBPR_PLUS_RETURN_REJECTED": {
            return {
                ...state,
                cbprPlusReturn: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "CBPR_PLUS_RETURN_FULFILLED": {
            return {
                ...state,
                cbprPlusReturn: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "CBPR_PLUS_RECALL_PENDING": {
            return {
                ...state,
                cbprPlusRecall: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "CBPR_PLUS_RECALL_REJECTED": {
            return {
                ...state,
                cbprPlusRecall: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "CBPR_PLUS_RECALL_FULFILLED": {
            return {
                ...state,
                cbprPlusRecall: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "CBPR_PLUS_RECALL_RESPONSE_PENDING": {
            return {
                ...state,
                cbprPlusRecallResponse: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "CBPR_PLUS_RECALL_RESPONSE_REJECTED": {
            return {
                ...state,
                cbprPlusRecallResponse: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "CBPR_PLUS_RECALL_RESPONSE_FULFILLED": {
            return {
                ...state,
                cbprPlusRecallResponse: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "TARGET_TWO_RETURN_PENDING": {
            return {
                ...state,
                targetTwoReturn: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "TARGET_TWO_RETURN_REJECTED": {
            return {
                ...state,
                targetTwoReturn: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "TARGET_TWO_RETURN_FULFILLED": {
            return {
                ...state,
                targetTwoReturn: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "TARGET_TWO_RECALL_PENDING": {
            return {
                ...state,
                targetTwoRecall: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "TARGET_TWO_RECALL_REJECTED": {
            return {
                ...state,
                targetTwoRecall: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "TARGET_TWO_RECALL_FULFILLED": {
            return {
                ...state,
                targetTwoRecall: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "TARGET_TWO_RECALL_RESPONSE_PENDING": {
            return {
                ...state,
                targetTwoRecallResponse: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "TARGET_TWO_RECALL_RESPONSE_REJECTED": {
            return {
                ...state,
                targetTwoRecallResponse: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "TARGET_TWO_RECALL_RESPONSE_FULFILLED": {
            return {
                ...state,
                targetTwoRecallResponse: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "TARGET_TWO_VALIDATE_PENDING": {
            return {
                ...state,
                targetTwoValidate: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "TARGET_TWO_VALIDATE_REJECTED": {
            return {
                ...state,
                targetTwoValidate: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "TARGET_TWO_VALIDATE_FULFILLED": {
            return {
                ...state,
                targetTwoValidate: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "SIC_EUROSIC_VALIDATE_PENDING": {
            return {
                ...state,
                sicEuroSicValidate: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "SIC_EUROSIC_VALIDATE_REJECTED": {
            return {
                ...state,
                sicEuroSicValidate: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "SIC_EUROSIC_VALIDATE_FULFILLED": {
            return {
                ...state,
                sicEuroSicValidate: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "SCRIPS_MEPS_VALIDATE_PENDING": {
            return {
                ...state,
                scripsMepsValidate: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "SCRIPS_MEPS_VALIDATE_REJECTED": {
            return {
                ...state,
                scripsMepsValidate: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "SCRIPS_MEPS_VALIDATE_FULFILLED": {
            return {
                ...state,
                scripsMepsValidate: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "SCRIPS_MEPS_ENVELOPE_PENDING": {
            return {
                ...state,
                scripsMepsEnvelope: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "SCRIPS_MEPS_ENVELOPE_REJECTED": {
            return {
                ...state,
                scripsMepsEnvelope: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "SCRIPS_MEPS_ENVELOPE_FULFILLED": {
            return {
                ...state,
                scripsMepsEnvelope: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "TARGET_TWO_MT_TRANSLATE_PENDING": {
            return {
                ...state,
                targetTwoMtTranslate: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "TARGET_TWO_MT_TRANSLATE_REJECTED": {
            return {
                ...state,
                targetTwoMtTranslate: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "TARGET_TWO_MT_TRANSLATE_FULFILLED": {
            return {
                ...state,
                targetTwoMtTranslate: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "TARGET_TWO_MX_TRANSLATE_PENDING": {
            return {
                ...state,
                targetTwoMxTranslate: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "TARGET_TWO_MX_TRANSLATE_REJECTED": {
            return {
                ...state,
                targetTwoMxTranslate: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "TARGET_TWO_MX_TRANSLATE_FULFILLED": {
            return {
                ...state,
                targetTwoMxTranslate: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        case "FEDNOW_VALIDATE_PENDING": {
            return {
                ...state,
                fedNowValidate: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "FEDNOW_VALIDATE_REJECTED": {
            return {
                ...state,
                fedNowValidate: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "FEDNOW_VALIDATE_FULFILLED": {
            return {
                ...state,
                fedNowValidate: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        default : {
            return state
        }
    }
}
