import React from "react";
import ErrorAnimation from "./ErrorAnimation";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
        this.setState({hasError: true});
    }

    render() {
        if (this.state.hasError) {
            return (
                <section className="error-page">
                    <article>
                        <ErrorAnimation />
                        <h4>
                            {'Oh, nooo! Something went wrong!'}
                        </h4>
                        <p>
                            {'Please refresh the page Ctrl+F5 (pc) & Cmd+Shift+R (mac) or try again later.'}
                        </p>
                    </article>
                </section>
            )
        }
        return this.props.children;
    }
}