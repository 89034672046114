import React from 'react';
import ReactGA from 'react-ga';
import gtag from 'ga-gtag';
import {
    ROUTE_PATHS,
    SUBHEADER_VALUES,
    MESSAGES_DOCUMENTATION_GITHUB
} from "./constants";


export const copyToClipBoard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

function checkPropValue(o, k, symbol) {
    if (o[k]['$']) {
        delete o[k]['$'];
    }
    if (o[k]['_']){
        o[k] = o[k]['_'];
        delete o[k]['_'];
    }
}

export function arrToObjIteration(o) {
    return Object.keys(o).forEach( (k)=> {
        if (o[k] !== null  &&  typeof o[k] === 'object') {
            if (!o[k].length) {
                checkPropValue(o, k);
                return arrToObjIteration(o[k]);
            }
            if (o[k].length > 0) {
                o[k]= o[k][0];
                checkPropValue(o, k);
                return arrToObjIteration(o[k]);
            }
        } else {
            return false;
        }
    });
}

export function getRespEls (obj) {
    switch(true) {
        case obj && obj.validity === 'valid' && (obj.reqType === 'validate' || obj.reqType.startsWith('translate') || obj.reqType.startsWith('cbpr')):
            return {img: 'msg-validity_icon bg-valid_img bg-sprites', bg: 'bg-valid msg-validity_bg', text: 'Valid message!'};
        case obj && (obj.validity === 'invalid' || obj.validity === 'error') && (obj.reqType === 'validate' || obj.reqType.startsWith('translate') || obj.reqType.startsWith('cbpr')):
            return {img: 'msg-validity_icon bg-invalid_img bg-sprites', bg: 'bg-invalid msg-validity_bg', text: 'Invalid message!'};
        default:
            return null;
    }
}

export function checkEmptyStr(str) {
    return (!str || str.length  === 0 || /^\s*$/.test(str) || !str.trim());
}

export function checkGenericServerError(resp) {
    return resp.error && resp.error.toLowerCase() === ('internal server error');
}

export function  checkRespData (resp) {
    if (typeof resp === 'object') {
        if (resp.length > 0) {
            return resp;
        } else {
            return [resp];
        }
    } else {
        return [resp.toString()]}
}

export function showSubHeader(path) {
    switch(true) {
        case path.includes(ROUTE_PATHS['checker']):
            return SUBHEADER_VALUES['checker']
        case path.includes(ROUTE_PATHS['api']):
            return  SUBHEADER_VALUES['api']
        case path.includes(ROUTE_PATHS['financialMessages']):
            return SUBHEADER_VALUES['financialMessages']
        default:
            return null;
    }
}


export function unparseableMsgResp (msg) {
    let msgType;
    let link;
    // eslint-disable-next-line default-case
    switch(msg) {
        case "mt":
            msgType = "SWIFT MT";
            link = MESSAGES_DOCUMENTATION_GITHUB['mt'];
        break;
        case "mx":
            msgType = "ISO20022";
            link =  MESSAGES_DOCUMENTATION_GITHUB['mx'];
         break;
        case "sepa":
            msgType = "SEPA";
            link =  MESSAGES_DOCUMENTATION_GITHUB['sepa'];
            break;
        case "cbprPlus":
            msgType = "CBPR+";
            link =  MESSAGES_DOCUMENTATION_GITHUB['cbprPlus'];
            break;
        case "pdf":
            msgType = "SWIFT MT";
            link =  MESSAGES_DOCUMENTATION_GITHUB['mt'];
            break;
        case "translateCbprMt":
            msgType = "SWIFT MT";
            link =  MESSAGES_DOCUMENTATION_GITHUB['mt'];
            break;
        case "translateCbprMx":
            msgType = "ISO20022";
            link =  MESSAGES_DOCUMENTATION_GITHUB['mx'];
            break;
        case "targetTwo":
            msgType = "TARGET2";
            link = MESSAGES_DOCUMENTATION_GITHUB['targetTwo'];
            break;
        case "translateTargetTwoMt":
            msgType = "SWIFT MT";
            link =  MESSAGES_DOCUMENTATION_GITHUB['mt'];
            break;
        case "translateTargetTwoMx":
            msgType = "ISO20022";
            link =  MESSAGES_DOCUMENTATION_GITHUB['mx'];
            break;
    }

    return <React.Fragment>
                <li key={'unparsed-key'}>{msg !== "pdf" ? "The message could not be parsed because of missing information." : "The message could not generate a PDF advice because of missing information."}</li>
                <br />
                <li key={'instruction-key'}>Learn about the structure of {msgType} messages through our detailed <br/>
                    <a href={link}
                       rel="noopener noreferrer"
                       target="_blank"
                       className="resp-doc--link">
                        Documentation
                    </a>
                </li>
            </React.Fragment>
}

export function sendGaEvent (obj) {
    if (!process.env.PUBLIC_URL.includes('uat.pc14.eu') && process.env.NODE_ENV === "production") {
        ReactGA.event(obj)
        gtag('event', obj.action, {
            'event_category': obj.category
        })
    }
}

export function getActiveSubscription(subs) {
    return subs.filter(sub => {
        return sub.active === true;
    });
}

export const scrollToAnchor =(hash)=> {
    const anchorId = hash.substring(hash.indexOf("#") + 1);
    const anchorItem = document.getElementById(anchorId);
    if (anchorItem){
        anchorItem.scrollIntoView({ behavior: "smooth" });
    }
}

export function compareTrialsState(headers, state) {
    return parseInt(headers) !== state;
}

export function getResultMessageType(msg, category) {
    if (category === 'translatorCbprToMt' || category === 'translatorTargetTwoToMt') {
        let no = msg.lastIndexOf('{2:O');
        let ni = msg.lastIndexOf('{2:I');
        let n = no > 0 ? no : ni;
        let str = msg.substring(n + 4).substr(0, 3);
        return (str ? 'MT' + str : '') + (getMsgSpecialType(msg));
    }

    if (category === 'translatorCbprToMx' ||
        category === 'translatorTargetTwoToMx' ||
        category === 'cbprPlusEnvelope' ||
        category === 'requestRecall' ||
        category === 'returnPayment') {
        let regex = msg.match(new RegExp(":xsd:(.*)\"", 'g'));
        let regRes = regex && regex.length > 0 ? (regex[0].includes('head') ? regex[1] : regex[0] ) : null;
        let str = regRes ? regRes.replace(':xsd:', '') : null;
        return str ? str.substring(0, str.length - 8): '';
    }
}

function getMsgSpecialType(msg) {
    switch (true) {
        case /:72:\/RETN\//.test(msg):
            return ' (Return)';
        case /\{3:\{119:COV\}/.test(msg):
            return ' COV';
        case /\{119:STP\}/.test(msg):
            return ' STP';
        default:
            return '';
    }
}

export const getBrowser =(userAgent)=> {

    var ua = userAgent, browser;

    // helper functions to deal with common regex
    function getFirstMatch(regex) {
        var match = ua.match(regex);
        return (match && match.length > 1 && match[1]) || '';
    }

    function getSecondMatch(regex) {
        var match = ua.match(regex);
        return (match && match.length > 1 && match[2]) || '';
    }

    // start detecting
    if (/opera|opr/i.test(ua)) {
        browser = {
            name: 'Opera',
            type: 'opera',
            version: getFirstMatch(/version\/(\d+(\.\d+)?)/i) || getFirstMatch(/(?:opera|opr)[\s/](\d+(\.\d+)?)/i)
        }
    } else if (/msie|trident/i.test(ua)) {
        browser = {
            name: 'Internet Explorer',
            type: 'msie',
            version: getFirstMatch(/(?:msie |rv:)(\d+(\.\d+)?)/i)
        }
    } else if (/chrome.+? edge/i.test(ua)) {
        browser = {
            name: 'Microsft Edge',
            type: 'msedge',
            version: getFirstMatch(/edge\/(\d+(\.\d+)?)/i)
        }
    } else if (/chrome|crios|crmo/i.test(ua)) {
        browser = {
            name: 'Google Chrome',
            type: 'chrome',
            version: getFirstMatch(/(?:chrome|crios|crmo)\/(\d+(\.\d+)?)/i)
        }
    } else if (/firefox/i.test(ua)) {
        browser = {
            name: 'Firefox',
            type: 'firefox',
            version: getFirstMatch(/(?:firefox)[ /](\d+(\.\d+)?)/i)
        }
    } else if (!(/like android/i.test(ua)) && /android/i.test(ua)) {
        browser = {
            name: 'Android',
            type: 'android',
            version: getFirstMatch(/version\/(\d+(\.\d+)?)/i)
        }
    } else if (/safari/i.test(ua)) {
        browser = {
            name: 'Safari',
            type: 'safari',
            version: getFirstMatch(/version\/(\d+(\.\d+)?)/i)
        }
    } else {
        browser = {
            name: getFirstMatch(/^(.*)\/(.*) /),
            version: getSecondMatch(/^(.*)\/(.*) /)
        }
        browser.type = browser.name.toLowerCase().replace(/\s/g, '');
    }
    return browser;
}

