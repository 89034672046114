export function setEnteredEmail(email) {
    return {
        type: "SET_REGISTRATION_EMAIL",
        enteredEmail: email
    }
}

export const clearRegistrationState = () => (dispatch) => {
    return new Promise(function(resolve) {
        dispatch({
            type: "CLEAR_REGISTRATION_STATE",
            enteredEmail: null,
            register: {
                fetched: false
            }
        })
        resolve(true);
    });
}

export function setUserPermission(userPermission) {
    return {
        type: "USER_PERMISSION",
        userPermission: userPermission
    }
}
